.popper, .tooltip {
    position: absolute;
    z-index: 9999;
    background: #85889C;
    color: white;
    max-width: 150px;
    border-radius: 3px;
    box-shadow: 0 0 2px rgba(0,0,0,0.5);
    padding: 3px;
    text-align: center;
    font-size: 10px;
    font-style: italic;
    font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
}