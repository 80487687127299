
html,
body,
body > div { /* the react root */
}


h2 {
  margin: 0;
  font-size: 16px;
}

ul {
  margin: 0;
  padding: 0 0 0 1.5em;
}

li {
  margin: 1.5em 0;
  padding: 0;
}

b { /* used for event dates/times */
  margin-right: 3px;
}

.demo-app {
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  min-height: 10%;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}

.demo-app-main {
  flex-grow: 1;
}

.fc .fc-toolbar-title {
  margin-right: 48px
}

.fc { /* the calendar root */
  max-width: 1100px;
  margin: 0 auto;
  background-color: #ffffff;
}

.fc-event {
  height: 50px !important;
}

.fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
  margin-top: 5px;
}

.fc-button-group {
  margin-top: 5px;
}

.fc-button-group .fc-addAbsence-button .fc-button .fc-button-primary {
  margin-top: 5px;
}
