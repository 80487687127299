.react-datepicker__triangle {
    transform: translate3d(180px, 0px, 0px) !important;
}

.react-datepicker {
    right: 17px
}

.date-input-field {
    width: 6rem;
}

.r-1sg8ghl {
    margin-top: -215px;
}

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open{
    opacity: 1;
}

.ReactModal__Overlay--before-close{
    opacity: 0;
}